export const proposalViewQuery = [
  'funcionario',
  'empresa',
  'contrato',
  'id',
  'localizador',
  'financeira',
  'status',
  'data_primeiro_vencimento',
  'ultimo_vencimento',
  'data_solicitacao',
  'valor_solicitado',
  'valor_recebivel',
  'prazo',
  'modalidade',
  'pagamento',
]

export const contractProposalViewQuery = [
  'status',
  'data_solicitacao',
  'valor_solicitado',
  'valor_liberado',
  'valor_financiado',
  'valor_seguro',
  'valor_juros',
  'valor_iof',
  'taxa_iof',
  'taxa_contrato',
  'taxa_cet_mes',
  'valor_tarifas',
  'prazo',
  'contrato__data_primeiro_vencimento',
  'contrato__data_ultimo_vencimento',
  'contrato__saldo_devedor',
  'contrato__status_averbacao',
  'contrato__status',
  'contrato__ccb_original_pdf',
  'contrato__ccb_original_html',
]
export const proposalCardQuery = [
  'id',
  'valor_solicitado',
  'valor_recebivel',
  'prazo',
  'ultimo_vencimento',
  'data_solicitacao',
  'contrato__id',
  'contrato__data_pagamento',
  'contrato__ultimo_vencimento',
  'contrato__status',
  'contrato__status_averbacao',
  'status',
  'localizador',
]

export const proposalDashboardQuery = [
  'id',
  'prazo',
  'valor_recebivel',
  'valor_solicitado',
  'valor_parcela',
  'status',
  'contrato__ccb_original_pdf',
  'contrato__ccb_original_html',
  'contrato__status',
  'contrato__status_averbacao',
  'data_solicitacao',
  'origem',
  'financeira',
  'migrado_em',
  'localizador',
  'convenio',
]

export const proposalListQuery = [
  'id',
  'nome',
  'sobrenome',
  'valor_solicitado',
  'valor_recebivel',
  'prazo',
  'status',
  'cpf',
  'cnpj',
  'nome_fantasia',
  'localizador',
  'documentacao_comprovacao',
  'data_solicitacao',
  'modalidade',
  'alienacao',
  'pagamento',
  'agente_certificado',
]

export const cdcProposalMetadata = ['metadados']

export const cpProposalSendDocuments = [
  'id',
  'valor_solicitado',
  'valor_recebivel',
  'prazo',
  'cpf',
  'documentacao_comprovacao',
]

export const corbanCEPProposal = [
  'status',
  'convenio',
  'empresa__nome_fantasia',
  'origem',
  'cliente_info_no_contrato__nome',
  'cliente_info_no_contrato__sobrenome',
  'cliente_info_no_contrato__cpf',
  'cliente_info_no_contrato__email',
  'cliente_info_no_contrato__pagamento',
  'funcionario__nome',
  'funcionario__sobrenome',
  'funcionario__cpf',
  'data_solicitacao',
  'data_primeiro_vencimento',
  'data_ultimo_vencimento',
  'telefone_celular',
  'financeira_valor_a_pagar',
  'prazo',
  'valor_financiado',
  'valor_parcela',
  'email',
  'id',
  'autorizador_msg',
  'valor_solicitado',
  'valor_recebivel',
  'localizador',
]

export const corbanCEPProposalCSV = [
  'status',
  'empresa__nome_fantasia',
  'id',
  'pagamento__banco',
  'pagamento__agencia',
  'pagamento__agencia_dac',
  'pagamento__conta',
  'pagamento__conta_dac',
  'origem',
  'funcionario__nome',
  'funcionario__sobrenome',
  'funcionario__cpf',
  'telefone_celular',
  'email',
  'data_solicitacao',
  'data_primeiro_vencimento',
  'ultimo_vencimento',
  'valor_financiado',
  'prazo',
  'valor_recebivel',
  'autorizador_msg',
]

export const pjProposalListQuery = [
  'id',
  'cliente_info_no_contrato',
  'origem',
  'status',
  'localizador',
  'data_solicitacao',
  'valor_solicitado',
  'valor_recebivel',
  'prazo',
  'cnpj',
  'nome_fantasia',
  'pagamento__banco',
  'pagamento__agencia',
  'pagamento__agencia_dac',
  'pagamento__conta',
  'pagamento__conta_dac',
  'pagamento__tipo_chave_pix',
  'pagamento__chave_pix',
]
